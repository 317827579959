.custom-table td {
  vertical-align: middle !important;
}

.custom-stats-table {
  width: 100%;
}

.custom-stats-table td:first-of-type {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

.custom-stats-table td:nth-of-type(2) {
  white-space: nowrap;
}

@media only screen and (max-width: 760px) {
  .custom-table table,
  .custom-table thead,
  .custom-table tbody,
  .custom-table th,
  .custom-table td,
  .custom-table tr {
    display: block;
  }
  .hide-on-mobile {
    display: none !important;
  }

  .custom-table thead > tr {
    position: absolute;
    top: -9999px;
    left: -999px;
  }

  .custom-table tr {
    border: 1px solid #ccc;
  }

  .custom-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .custom-table td::before {
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: 700;
  }

  .custom-table-trials td:nth-of-type(1)::before {
    content: "Product";
  }

  .custom-table-trials td:nth-of-type(2)::before {
    content: "Created";
  }

  .custom-table-trials td:nth-of-type(3)::before {
    content: "Expires";
  }

  .custom-table-trials td:nth-of-type(4)::before {
    content: "Status";
  }

  .custom-table-trials td:nth-of-type(5)::before {
    content: "Action";
  }

  .custom-table-trials td:nth-of-type(6)::before {
    content: "View Account";
  }

  .custom-table-product td:nth-of-type(1)::before {
    content: "Account";
  }

  .custom-table-product td:nth-of-type(2)::before {
    content: "Type / Level";
  }

  .custom-table-product td:nth-of-type(3)::before {
    content: "Created";
  }

  .custom-table-product td:nth-of-type(4)::before {
    content: "Expires";
  }

  .custom-table-product td:nth-of-type(5)::before {
    content: "Status";
  }

  .custom-table-product td:nth-of-type(6)::before {
    content: "Action";
  }

  .custom-table-product td:nth-of-type(7)::before {
    content: "View Account";
  }

  .custom-table-license td:nth-of-type(1)::before {
    content: "Product";
  }

  .custom-table-license td:nth-of-type(2)::before {
    content: "Type";
  }

  .custom-table-license td:nth-of-type(3)::before {
    content: "Created";
  }

  .custom-table-license td:nth-of-type(4)::before {
    content: "Expires";
  }

  .custom-table-license td:nth-of-type(5)::before {
    content: "View";
  }

  .custom-table-subscription td:nth-of-type(1)::before {
    content: "Account Name";
  }

  .custom-table-subscription td:nth-of-type(2)::before {
    content: "Level";
  }

  .custom-table-subscription td:nth-of-type(3)::before {
    content: "View";
  }

  .custom-table-cloud-billing td:nth-of-type(1)::before,
  .custom-table-wurfljs-billing td:nth-of-type(1)::before {
    content: "Date";
  }

  .custom-table-cloud-billing td:nth-of-type(2)::before,
  .custom-table-wurfljs-billing td:nth-of-type(2)::before {
    content: "Card";
  }

  .custom-table-cloud-billing td:nth-of-type(3)::before,
  .custom-table-wurfljs-billing td:nth-of-type(3)::before {
    content: "Amount";
  }

  .custom-table-cloud-billing td:nth-of-type(4)::before,
  .custom-table-wurfljs-billing td:nth-of-type(4)::before {
    content: "Status";
  }

  .custom-table-cloud-billing td:nth-of-type(5)::before,
  .custom-table-wurfljs-billing td:nth-of-type(5)::before {
    content: "View";
  }

  .custom-table-cloud-plan td:nth-of-type(1)::before,
  .custom-table-cloud-change td:nth-of-type(1)::before {
    content: "Plan";
  }

  .custom-table-cloud-plan td:nth-of-type(2)::before,
  .custom-table-cloud-change td:nth-of-type(2)::before {
    content: "Cost";
  }

  .custom-table-cloud-change td:nth-of-type(3)::before {
    content: "API Keys";
  }

  .custom-table-cloud-plan td:nth-of-type(3)::before,
  .custom-table-cloud-change td:nth-of-type(4)::before {
    content: "Capabilities";
  }

  .custom-table-cloud-plan td:nth-of-type(4)::before,
  .custom-table-cloud-change td:nth-of-type(5)::before {
    content: "Detections";
  }

  .custom-table-cloud-plan td:nth-of-type(5)::before {
    content: "Action";
  }

  .custom-table-orders-standard td:nth-of-type(1)::before,
  .custom-table-orders-external td:nth-of-type(1)::before {
    content: "Creation Date";
  }

  .custom-table-orders-standard td:nth-of-type(2)::before,
  .custom-table-orders-external td:nth-of-type(2)::before {
    content: "Due Date";
  }

  .custom-table-orders-standard td:nth-of-type(3)::before {
    content: "Card";
  }

  .custom-table-orders-external td:nth-of-type(3)::before {
    content: "Balance";
  }

  .custom-table-orders-standard td:nth-of-type(4)::before,
  .custom-table-orders-external td:nth-of-type(4)::before {
    content: "Amount";
  }

  .custom-table-orders-standard td:nth-of-type(5)::before,
  .custom-table-orders-external td:nth-of-type(5)::before {
    content: "Actions";
  }

  .custom-table-ie-plan td:nth-of-type(1)::before,
  .custom-table-ie-change td:nth-of-type(1)::before {
    content: "Plan";
  }

  .custom-table-ie-plan td:nth-of-type(2)::before,
  .custom-table-ie-change td:nth-of-type(2)::before {
    content: "Cost";
  }

  .custom-table-ie-plan td:nth-of-type(3)::before,
  .custom-table-ie-change td:nth-of-type(3)::before {
    content: "Usage";
  }

  .custom-table-ie-plan td:nth-of-type(4) {
    text-align: left !important;
  }

  .custom-table-ie-plan td:nth-of-type(4)::before {
    content: "Action";
  }

  .custom-table-ie-purge td:nth-of-type(1)::before {
    content: 'Origin';
  }

  .custom-table-ie-purge td:nth-of-type(2)::before {
    content: 'Expression';
  }

  .custom-table-ie-purge td:nth-of-type(3)::before {
    content: 'Date';
  }

  .custom-table-ie-purge td:nth-of-type(4)::before {
    content: 'Status';
  }

  .custom-table-ie-purge td:nth-of-type(5)::before {
    content: 'Action';
  }

  .custom-table-ie-summary td:nth-of-type(1)::before {
    content: 'Hostname';
  }

  .custom-table-ie-summary td:nth-of-type(2)::before {
    content: 'Origin';
  }

  .custom-table-ie-billing-history td:nth-of-type(1)::before {
    content: 'Date';
  }

  .custom-table-ie-billing-history td:nth-of-type(2)::before {
    content: 'Card';
  }

  .custom-table-ie-billing-history td:nth-of-type(3)::before {
    content: 'Amount';
  }

  .custom-table-ie-billing-history td:nth-of-type(4)::before {
    content: 'Status';
  }

  .custom-table-ie-billing-history td:nth-of-type(5) {
    text-align: left !important;
  }

  .custom-table-ie-billing-history td:nth-of-type(5)::before {
    content: 'Action';
  }

  .custom-table-wurfljs-capabilities td:nth-of-type(1)::before {
    content: 'Capability';
  }

  .custom-table-wurfljs-capabilities td:nth-of-type(2)::before {
    content: 'Description';
  }

  .custom-table-wurfljs-plan td:nth-of-type(1)::before,
  .custom-table-wurfljs-upgrade td:nth-of-type(1)::before {
    content: 'Plan';
  }

  .custom-table-wurfljs-plan td:nth-of-type(2)::before,
  .custom-table-wurfljs-upgrade td:nth-of-type(2)::before {
    content: 'Cost';
  }

  .custom-table-wurfljs-plan td:nth-of-type(3)::before,
  .custom-table-wurfljs-upgrade td:nth-of-type(5)::before {
    content: 'Detections';
  }

  .custom-table-wurfljs-plan td:nth-of-type(4)::before {
    content: 'Action';
  }

  .custom-table-wurfljs-upgrade td:nth-of-type(3)::before {
    content: 'Domains';
  }

  .custom-table-wurfljs-upgrade td:nth-of-type(4)::before {
    content: 'CNames';
  }

  .custom-table-cloud-receipt tbody {
    border: none !important;
  }

  .custom-table-cloud-receipt td {
    border: none !important;
  }

  .receipt-table td:nth-of-type(1)::before {
    content: 'Item';
  }

  .receipt-table td:nth-of-type(2)::before {
    content: 'Quantity';
  }

  .receipt-table td:nth-of-type(3)::before {
    content: 'Price';
  }

  .receipt-table td:nth-of-type(4)::before {
    content: 'Amount';
  }

  .custom-table-receipt-table td:nth-of-type(1)::before {
    content: 'Product/Service';
  }

  .custom-table-receipt-table td:nth-of-type(2)::before {
    content: 'Description';
  }

  .custom-table-receipt-table td:nth-of-type(3)::before {
    content: 'Quantity';
  }

  .custom-table-receipt-table td:nth-of-type(4)::before {
    content: 'Price';
  }

  .custom-table-receipt-table td:nth-of-type(5)::before {
    content: 'Amount';
  }

  .custom-stats-table td:first-of-type {
    max-width: 300px !important;
  }

  .custom-stats-table td:nth-of-type(1)::before {
    content: 'Request URI';
  }

  .custom-stats-table td:nth-of-type(2)::before {
    content: 'Count';
  }
}

.custom-table-cloud-receipt {
  font-size: 1.1875rem !important;
}

.custom-table-cloud-receipt tbody {
  border: none !important;
}

.custom-table-cloud-receipt td {
  border: none !important;
}

.custom-table-cloud-receipt tbody tr:nth-child(even) {
  background-color: #fff !important;
}

.receipt-table table {
  background-color: #f5f5f5;
}

.receipt-table table thead,
.receipt-table table tbody {
  border: 0 !important;
}

.receipt-table table thead {
  background: #f5f5f5 !important;
}

.receipt-table th,
.receipt-table td {
  font-size: 1.1875rem;
}

.receipt-table td,
.receipt-table tr,
.receipt-table thead {
  color: #696969;
  background-color: #f5f5f5;
}

.css-arrow {
  width: 5px;
  height: 50px;
  background-color: #696969;;
  margin: 0 30px;
  transform: rotate(90deg);
}

.css-arrow:before,
.css-arrow:after {
  position: absolute;
  content: "";
  border-width: 0 10px 20px 10px;
  border-style: solid;
  border-color: transparent transparent #696969 transparent;
}

.css-arrow:before {
  transform: rotate(180deg);
  top: 50px;
  right: -7px;
}

.css-arrow:after {
  top: -20px;
  right: -7px;
}


