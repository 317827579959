.custom-breadcrumbs {
  margin: 0 0 1rem;
  list-style: none;
}

.custom-breadcrumbs::before,
.custom-breadcrumbs::after {
  display: table;
  content: ' ';
}

.custom-breadcrumbs::after {
  clear: both;
}

.custom-breadcrumbs li {
  float: left;
  font-size: 0.6875rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
}

.custom-breadcrumbs a {
  color: #1779ba;
}

.custom-breadcrumbs a:hover {
  text-decoration: underline;
}

.custom-breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.custom-breadcrumbs {
  margin-bottom: 0.625rem;
}

.custom-breadcrumbs li {
  line-height: 1.1875rem;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
}

.custom-breadcrumbs li a {
  color: #696969;
}

.custom-breadcrumbs > .arrow::after {
  content: '>';
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

.custom-breadcrumbs li {
  font-size: 1rem !important;
  color: #696969 !important;
}

.custom-breadcrumbs li a {
  text-decoration: underline;
  font-size: inherit;
}

.custom-breadcrumbs li:last-child {
  float: right;
}

.custom-breadcrumbs li:last-child span {
  cursor: pointer;
  border: 0.125rem solid #298dff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3.125rem;
  color: #298dff !important;
}

.custom-breadcrumbs li:last-child:hover span {
  border-color: #1b4886;
  color: #1b4886 !important;
}
