.menu-wrapper {
  height: 4.7vw !important;
  max-height: 67.5px !important;
}

.menu-wrapper > .row {
  height: 4.7vw !important;
  padding-left: 0.6vw !important;
  padding-right: 0.6vw !important;
  max-height: 67.5px !important;
}

.menu-wrapper > .row > .medium-12 {
  height: 4.7vw !important;
  padding-left: 0.9375vw !important;
  padding-right: 0.9375vw !important;
  max-height: 67.5px !important;
}

.menu-wrapper .sticky-header {
  box-shadow: 0.40175vw 0.47875vw 0.9375vw rgba(0, 0, 0, 0.2);
}

.left-menu {
  height: 4.7vw !important;
  max-height: 67.5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.right-menu {
  height: 4.7vw !important;
  max-height: 67.5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.menu-wrapper .menu-logo {
  margin-top: 0 !important;
  margin-right: 0.9375vw !important;
  float: left !important;
}

.menu-wrapper .menu-logo-ie {
  margin-top: 0 !important;
  margin-right: 0.9375vw !important;
  float: left !important;
}

.menu-wrapper .menu-logo a img {
  width: 11.90625vw !important;
  height: 2.35vw !important;
  max-height: 34.1875px !important;
  max-width: 171.109px !important;
}

.menu-wrapper #top-nav > ul > li > a {
  font-size: 1.25vw !important;
}

@media only screen and (min-width: 1440px) {
  .menu-wrapper #top-nav > ul > li > a {
    font-size: 18px !important;
  }
}

.menu-wrapper .menu-cta {
  width: 10.125vw !important;
  height: 2.3vw !important;
  max-height: 33.75px !important;
  max-width: 146.25px !important;
  line-height: 2.3vw !important;
  border-radius: 3.125vw !important;
  font-size: 1.3vw !important;
  box-shadow: 0.40175vw 0.47875vw 0.9375vw rgba(0, 0, 0, 0.2) !important;
  margin-left: 1.25vw !important;
}

@media only screen and (min-width: 1384.6px) {
  .menu-wrapper .menu-cta {
    font-size: 18px !important;
  }
}

@media only screen and (min-width: 1467.4px) {
  .menu-wrapper .menu-cta {
    line-height: 33.75px !important;
  }
}

.menu-wrapper .search-btn {
  width: 1.45vw !important;
  height: 1.16875vw !important;
  margin-left: 0.625vw !important;
  max-width: 21.1406px !important;
  max-height: 21.0312px !important;
}

.menu-wrapper .right-menu li.menu-item-has-children ul.dropdown {
  opacity: 0;
  display: none;
  position: absolute;
  background: #298dff;
  top: 3.75rem;
  padding: 1.875rem;
  z-index: 999;
  margin: 0;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50% !important;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
  width: 12rem !important;
  list-style: none;
  text-align: left;
}

.menu-wrapper #header-nav-2 li.menu-item-has-children {
  position: relative;
}
