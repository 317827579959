#body {
  font-family: 'Source Sans Pro', sans-serif;
}

.required::after {
  content: " *";
}

.info-tag {
  color: #033c73;
}

.info-tag::after {
  content: " *";
}

.warning-tag {
  color: #c09853;
}

.warning-tag::after {
  content: " *";
}

.row {
  padding-bottom: 5px;
}

.error {
  border: 2px solid red !important;
  padding: 20px !important;
}

.error-text {
  color: red;
  font-style: italic;
}

account-block > .account-block-title {
  display: inline-block;
  text-decoration: none;
  font-size: 1.5rem;
  background-image: linear-gradient(to right, #298dff, #298dff);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 50% 2px;
  transition: background-size 0.5s ease;
  margin-bottom: 10px;
  color: #298dff;
}

account-block > .account-block-body {
  font-size: 1.1875rem;
  color: #696969;
}

.account-block-title:hover {
  background-size: 100% 2px;
}

details-block > .details-block-title {
  display: inline-block;
  text-decoration: none;
  font-size: 1.5em;
  background-image: linear-gradient(to right, #1b4886, #1b4886);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 50% 2px;
  transition: background-size 0.5s ease;
  margin-bottom: 20px;
  color: #1b4886;
}

.details-block-title:hover {
  background-size: 100% 2px;
}

.license-block {
  line-height: normal;
  color: blue;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.capability {
  margin-right: 5px;
  border-radius: 3.125rem;
  display: inline-block;
  line-height: 1.875rem;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  width: 100%;
  word-wrap: break-word;
}

.capability-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.capability-list {
  list-style-type: none;
}

.capability-list li {
  float: left;
  margin: 10px;
}

.capability-list li a {
  margin: 0 4px;
  color: #eee;
  padding: 10px 20px;
  background: #3c66ad;
  font-size: 16px;
  font-weight: bold;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.account_block {
  display: block;
  float: left;
  width: 32%;
  margin-right: 1%;
}

.input-addon-custom {
  min-width: 60px;
}

.read-write {
  margin-bottom: 5px;
}

.read-write > span {
  color: #1b4886;
  font-weight: 700;
  font-size: 1.1875rem;
}

.read-write div {
  font-size: 1.1875rem;
  font-weight: 400;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
}

.read-write-link a {
  color: #696969;
  text-decoration: underline;
  font-weight: 600;
  line-height: 1.4375rem;
}

.label-custom-warning {
  background: #c86b1f;
}

#loading-bar .bar {
  background: #298dff !important;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: #298dff !important;
  border-left-color: #298dff !important;
}

#loading-bar .peg {
  -moz-box-shadow: #298dff 1px 0 6px 1px !important;
  -ms-box-shadow: #298dff 1px 0 6px 1px !important;
  -webkit-box-shadow: #298dff 1px 0 6px 1px !important;
  box-shadow: #298dff 1px 0 6px 1px !important;
}

.loading-spinner {
  -webkit-animation: spin 1s linear infinite !important;
  -moz-animation: spin 1s linear infinite !important;
  animation: spin 1s linear infinite !important;
}

.panel-default-custom {
  background: #298dff;
}

.panel-default-custom > .panel-heading {
  color: #fff;
  background: #0d7eff;
}

.panel-default-custom > .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #0d7eff !important;
}

.sub-panel-default-custom {
  background: #ebebeb;
}

.sub-panel-default-custom > .panel-heading[aria-selected="false"] {
  color: #fff;
  background: #1b4886;
}

.sub-panel-default-custom > .panel-heading[aria-selected="true"] {
  color: #1b4886;
  background: #ebebeb;
}

.sub-panel-default-custom > .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #ebebeb !important;
}

.account-shadow {
  background: #fff;
  padding: 1.875rem;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
  min-height: 20rem;
}

.matching-btn {
  display: inline-block;
  line-height: 1.875rem;
  border-radius: 3.125rem !important;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 700;

  /* margin-left: 1.25rem; */
  padding: 5px 10px;
  width: 100%;
}

.matching-btn-text-width {
  display: inline-block;
  line-height: 1.875rem;
  border-radius: 3.125rem;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 1.25rem;
  padding: 5px 10px;
}

.small-matching-btn {
  display: inline-block;
  line-height: 1.875rem;
  border-radius: 3.125rem !important;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 5px 10px;
}

.small-matching-btn:hover {
  color: #364a1c;
}

.small-matching-btn-disabled {
  display: inline-block;
  line-height: 1.875rem;
  border-radius: 3.125rem;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 5px 10px;
  opacity: 0.5;
}

.mover {
  color: #298dff;
  cursor: pointer;
}

.mover-disabled {
  color: #298dff;
  opacity: 0.5;
  cursor: default;
}

.matching-btn[type="submit"] {
  border-radius: 3.125rem !important;
}

.matching-btn-text-width[type="submit"] {
  border-radius: 3.125rem !important;
}

.plans-btn {
  width: 8.125rem;
  border: 0.125rem solid #1b4886;
  color: #1b4886;
}

.plans-btn:hover {
  border-color: #298dff;
  color: #298dff;
}

.plans-btn-word-width {
  border: 0.125rem solid #1b4886;
  color: #1b4886;
  white-space: nowrap;
  display: inline-block;
}

.plans-btn:hover,
.plans-btn-word-width:hover {
  border-color: #298dff;
  color: #298dff;
}

.plans-btn-selected {
  width: 8.125rem;
  background-color: #1b4886;
  border-color: #1b4886;
  color: #fff;
}

.plans-btn-selected-word-width {
  background-color: #1b4886;
  border-color: #1b4886;
  color: #fff;
  white-space: nowrap;
  display: inline-block;
}

.primary {
  background: #298dff;
  color: #fff;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
}

.no-shadow {
  box-shadow: none !important;
}

.shadow {
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
}

.primary:hover {
  background: #153c6a;
  color: #fff;
}

.primary-disabled {
  background: #298dff;
  color: #fff;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
  opacity: 0.6;
}

.goto {
  background: #547b96;
  color: #fff;
  text-decoration: none !important;
}

.goto:hover {
  background: #1c64a1;
  color: #fff;
}

.info-color {
  background: #033c73 !important;
  color: #fff;
}

.info-color-hover {
  background: #033c73 !important;
  color: #fff;
  cursor: pointer;
}

.info-color-hover:hover {
  background: #0569c7 !important;
}

.danger-color-hover {
  background: #da4f49;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #ee5f5b, #ee5f5b);
  cursor: pointer;
}

.danger-color-hover:hover {
  opacity: 0.8;
}

.success-color-hover {
  background: #688e36 !important;
  color: #fff;
  cursor: pointer;
}

.success-color-hover:hover {
  background: #364a1c !important;
  border-color: #364a1c;
}

.info {
  background: #033c73;
  color: #fff;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
}

.info:hover {
  color: #fff;
  background: #0569c7;
}

.info-disabled {
  background: #033c73;
  color: #fff;
  opacity: 0.5;
  cursor: default;
}

.default {
  border: 0.125rem solid #298dff;
  color: #298dff;
}

.default:hover {
  color: #1b4886;
  border-color: #1b4886;
}

.default-disabled {
  border: 0.125rem solid #298dff;
  color: #298dff;
  opacity: 0.5;
  cursor: default;
}

.success {
  background: #688e36 !important;
  color: #fff;
}

.success-disabled {
  background: #688e36 !important;
  color: #fff;
  cursor: default;
}

.success-disabled:hover {
  color: #fff !important;
}

.success-btn {
  background: #688e36 !important;
  color: #fff;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
}

.success-btn:hover {
  background: #364a1c !important;
  border-color: #364a1c;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
}

.danger {
  background: #da4f49;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #ee5f5b, #ee5f5b);
}

.danger-btn {
  background: #da4f49;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #ee5f5b, #ee5f5b);
}

.danger-btn:hover {
  opacity: 0.8;
}

.remove-btn {
  background: #da4f49;
  color: #fff;
}

.remove-btn:hover {
  background: #a92723;
  color: #fff;
}

.remove-btn-disabled {
  background: #da4f49;
  color: #fff;
  opacity: 0.5;
}

.remove-btn-disabled:hover {
  color: #fff;
}

.warning {
  background: #c86b1f;
  color: #fff;
}

.warning:hover {
  background: #d14704;
}

.warning-disabled {
  opacity: 0.5;
  cursor: default;
}

.warning-disabled:hover {
  background: #c86b1f !important;
}

.account-block-body a {
  color: #696969 !important;
  font-weight: bold;
  text-decoration: underline !important;
}

.account-menu a {
  color: #298dff;
}

.account-menu > li.active > a,
.account-menu > li.active > a:hover,
.account-menu > li.active > a:focus {
  background-color: #298dff;
}

.license-home {
  background-color: #f5f5f5;
  padding-top: 50px;
  padding-bottom: 20px;
}

.license-home > h1,
.license-home > h2 {
  color: #1b4886;
}

.license-home > p {
  color: #696969;
  font-size: 16px;
}

.license-home h3 {
  color: #1b4886;
  font-size: 1.5rem;
}

.account-left-menu {
  padding-left: 0;
  padding-right: 0;
}

.account-left-menu > ul {
  margin-left: 0;
}

.account-details {
  height: 50px;
  background-color: #ebebeb;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  position: relative;
  min-height: 5.625rem;
}

.account-details::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ebebeb;
  clear: both;
  z-index: 1;
  pointer-events: none;
  display: block;
  border-width: 1.875rem;
  margin-left: -1.875rem;
}

.account-details > h3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #696969;
  font-weight: 700;
  padding: 1.875rem 5px 0;
}

.plan-details {
  height: 50px;
  background-color: #0d7eff;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  position: relative;
  min-height: 5.625rem;
}

.plan-details::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #0d7eff;
  clear: both;
  z-index: 1;
  pointer-events: none;
  display: block;
  border-width: 1.875rem;
  margin-left: -1.875rem;
}

.plan-details > h3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-top: 1.875rem;
  padding-bottom: 0;
  color: #fff;
  font-weight: 700;
}

.home-block h1,
.home-block h2 {
  color: #1b4886;
}

.home-block p {
  color: #696969;
  font-size: 1.1875rem;
}

.home-block .alert-danger p {
  color: #a94442 !important;
  font-size: 1rem;
}

.home-block p > a {
  color: #696969 !important;
  font-weight: bold;
  text-decoration: underline !important;
}

.home-block h5 {
  color: #696969;
}

.home-block .square ul {
  list-style: none;
}

.home-block .square ul li::before {
  color: #696969;
  font-size: 1.2em;
  content: "\25A0";
  padding-right: 1em;
  position: relative;
  top: 0;
}

.home-block li {
  color: #696969;
  font-size: 1.2em;
}

.home-block label {
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.4375rem;
  color: #696969;
  margin-bottom: 0.625rem;
}

.home-block table {
  background-color: #f5f5f5;
  font-size: 1.2em;
}

.home-block table thead {
  background: #f5f5f5 !important;
}

.home-block td,
.home-block tr,
.home-block thead {
  color: #696969;
  background-color: #f5f5f5;
}

.home-block ol > li {
  color: #696969;
  font-size: 1.1875rem;
}

.license-home input,
.home-block textarea,
.home-block select,
.account-block-body select {
  color: #298dff;
}

.capability-feature ul {
  list-style: none;
  margin-left: 0;
}

.capability-feature h3,
.capability-feature .description {
  color: #1b4886;
}

.capability-feature .description {
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  margin: 0;
}

.capability-feature .features {
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  color: #696969;
  margin-bottom: 0.3125rem;
}

.capability-feature strong {
  font-weight: 700;
}

.search-box {
  background: #ebebeb;
  padding: 1.875rem;
  margin-bottom: 1.875rem;
}

.search-box h3 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #298dff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin: 0 0 1.25rem;
  padding: 0;
}

.search-box form {
  position: relative;
  margin-bottom: 0;
}

.search-box form input {
  height: 3.125rem;
  line-height: 3.125rem;
  background: #fff;
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}

.search-box form img {
  position: absolute;
  right: 1.25rem;
  top: 0.9375rem;
  margin-bottom: 0;
  width: 1.175rem;
  height: 1.16875rem;
}

.search-box-cloud {
  background: #ebebeb;
  padding-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.search-box-cloud h3 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #298dff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin: 0 0 1.25rem;
  padding: 0;
}

.search-box-cloud form {
  position: relative;
  margin-bottom: 0;
}

.search-box-cloud form input {
  height: 3.125rem;
  line-height: 3.125rem;
  background: #fff;
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}

.search-box-cloud form img {
  position: absolute;
  right: 1.25rem;
  top: 0.9375rem;
  margin-bottom: 0;
  width: 1.175rem;
  height: 1.16875rem;
}

.filters-box {
  background: #ebebeb;
  padding: 1.875rem 1.875rem 0.9375rem;
  margin-bottom: 1.875rem;
}

.filters-box h3 {
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin: 0;
  padding: 0 0 1.25rem;
  border-bottom: 0.125rem solid #dcdcdc;
}

.filters-box .filters-box-content .panel-default {
  border-width: 0 !important;
  border-color: #ebebeb !important;
  border-bottom: 0.125rem solid #dcdcdc !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.filters-box .filters-box-content .panel-heading {
  background-color: #ebebeb !important;
  border-width: 0 !important;
  border-color: #ebebeb !important;
  padding-left: 0;
  padding-right: 0;
}

.filters-box .filters-box-content .panel-heading .panel-title {
  background: none;
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  line-height: 1.4375rem;
  padding: 0;
  position: relative;
}

.filters-box .filters-box-content .panel-heading .panel-title a::before {
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-up-sb.svg") no-repeat center center;
  top: 0;
  content: '';
  width: 1.25rem;
  height: auto;
  margin-top: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: 1.25rem 0.75rem;

  /* Safari */
  transition: all 0.2s;
}

.open {
  color: #298dff;
}

.open::before {
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-up-sb.svg") no-repeat center center;
  -ms-transform: rotate(180deg);

  /* IE 9 */
  -webkit-transform: rotate(180deg);

  /* Safari */
  transform: rotate(180deg);
}

.filters-box .filters-box-content .panel-body {
  padding: 0;
  background-color: #ebebeb;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-top-width: 0 !important;
}

.filters-box .filters-box-content .panel-body .filter-item {
  padding: 0;
  margin-bottom: 0.125rem;
}

.filters-box .filters-box-content .panel-body .filter-item label {
  color: #696969;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:not(:checked),
.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:checked {
  position: absolute;
  left: -624.9375rem;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:not(:checked) + label,
.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.5625rem;
  cursor: pointer;
  margin-left: 0;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:not(:checked) + label::before,
.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:checked + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 1.0625rem;
  height: 1.0625rem;
  border: 0.125rem solid #696969;
  background: none;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:not(:checked) + label::after,
.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:checked + label::after {
  content: '×';
  position: absolute;
  top: -0.6875rem;
  left: 0.15625rem;
  font-size: 1.5rem;
  color: #696969;
  line-height: 1.75;
  transition: all 0.2s;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:not(:checked) + label::after {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:disabled:not(:checked) + label::before,
.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:disabled:checked + label::before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:disabled:checked + label::after {
  color: #999;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:disabled + label {
  color: #aaa;
}

.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:checked:focus + label::before,
.filters-box .filters-box-content .panel-body .filter-item [type="checkbox"]:not(:checked):focus + label::before {
  border: 1px solid #fff;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.capability-accordion-list .count {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #696969;
}

.capability-accordion-list {
  margin-bottom: 1.875rem;
}

.capability-accordion-list h3 {
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin: 0;
  padding: 0 0 1.25rem;
}

.capability-accordion-list .capability-accordion-list-content .panel-default {
  border-width: 0 !important;
  border-color: #ebebeb !important;
  border-bottom: 0.125rem solid #fff !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading,
.capability-accordion-list .capability-accordion-list-content .panel-heading[aria-selected="false"] {
  background-color: #1b4886 !important;
  border-width: 0 !important;
  border-color: #1b4886 !important;
  border: none;
  border-bottom: 0.125rem solid #f5f5f5;
  padding: 0.9375rem 3.125rem 0.9375rem 1.875rem;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading[aria-selected="true"] {
  background: #ebebeb !important;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading[aria-selected="true"] .panel-title a,
.capability-accordion-list .capability-accordion-list-content .panel-heading[aria-selected="true"] .panel-title p {
  color: #1b4886 !important;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading .panel-title {
  background: none;
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 700;
  border: none;
  line-height: 1.4375rem;
  position: relative;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading .panel-title a {
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading .panel-title p {
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  margin: 0;
  width: 95%;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading .panel-title a::before {
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-up-w.svg") no-repeat center center;
  top: 0;
  content: '';
  width: 1.25rem;
  height: auto;
  margin-top: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: 1.25rem 0.75rem;

  /* Safari */
  transition: all 0.2s;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading .panel-title a[aria-expanded=true] {
  color: #298dff;
}

.capability-accordion-list .capability-accordion-list-content .panel-heading .panel-title a[aria-expanded=true]::before {
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-up-b.svg") no-repeat center center;
  -ms-transform: rotate(180deg);

  /* IE 9 */
  -webkit-transform: rotate(180deg);

  /* Safari */
  transform: rotate(180deg);
}

.capability-accordion-list .capability-accordion-list-content .panel-body {
  padding: 0;
  background-color: #ebebeb;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-top-width: 0 !important;
}

.capability-accordion-list .load-link {
  display: block;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.capability-accordion-list .load-link a {
  color: #1b4886 !important;
}

.capability-accordion-list .load-link::after {
  content: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-up-b.svg");
  width: 1.3125rem;
  height: 0.8125rem;
  display: inline-block;
}

.capability-accordion-list .capability-accordion-list-content .capability-feature {
  padding: 0.9375rem 3.125rem 0.9375rem 1.875rem;
  background: #ebebeb;
  border-bottom: 0.125rem solid #ebebeb;
}

@media (min-width: 992px) {
  .equal > section[class*='col-'],
  .equal > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 1 auto;
  }
}

insight-block {
  margin-bottom: 10px;
  margin-top: 10px;
  border-right: 0.125rem solid #ebebeb;
}

insight-block > .insight-block-title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
  color: #1b4886;
  margin-bottom: 10px;
}

.insight-block-body form {
  position: relative;
  margin-bottom: 0;
}

.insight-block-body form input {
  height: 3.125rem;
  background: #fff;
  border: none;
  box-shadow: none;
  margin-bottom: 0;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  color: #1b4886;
}

.insight-block-body form span {
  position: absolute;
  right: 1.25rem;
  top: 0.9375rem;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
  border: 0.125rem solid #298dff;
  color: #298dff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3.125rem;
}

.insight-block-body form span:hover {
  color: #1b4886;
  border-color: #1b4886;
}

.insight-block-body ul {
  list-style: none;
  margin-left: 0;
}

.insight-block-body ul > li {
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  color: #1b4886;
}

.chart-container::before {
  background: #fff;
  position: absolute;
  top: -1.875rem;
  bottom: -1.875rem;
  right: 0;
  width: 65%;
  display: block;
  content: '';
  z-index: 1;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
}

.equal {
  padding-bottom: 0;
}

.insight {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pricing {
  padding: 1.875rem 0;
  margin-top: 20px;
}

.left {
  margin-right: 1.875rem;
}

.right {
  margin-left: 1.875rem;
}

.pricing > h3 {
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4375rem;
  margin: 0 0 0.625rem;
}

.pricing > h4 {
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin: 0;
}

.pricing > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pricing > ul > li:first-child {
  margin-top: 10px;
  border-top: 0.125rem solid #ebebeb;
}

.pricing > ul > li {
  position: relative;
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  padding: 0.3125rem 0.9375rem;
  border-bottom: 0.125rem solid #ebebeb;
}

.pricing p {
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  margin: 0.625rem 0 0.9375rem;
}

.pricing form > div > label {
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.4375rem;
  color: #696969;
  margin-bottom: 0.625rem;
}

.pricing form input {
  height: 3.125rem;
  line-height: 3.125rem;
  box-shadow: none;
  margin-bottom: 0;
  color: #298dff;
}

.pricing form select,
.pricing form input::placeholder {
  color: #298dff;
  opacity: 0.5;
}

.pricing form select,
.pricing form .multiselect > div > button {
  height: 3.125rem;
}

.pricing form .multiselect > div > button {
  border-radius: 4px;
  font-size: 14px;
}

.pricing textarea {
  min-height: 160px;
}

.pricing .panel-body p {
  color: #696969;
}

.billing {
  background-color: #ebebeb;
}

.billing p {
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
  margin: 0.625rem 0 0.9375rem;
}

.consent-item,
.reverse-consent-item {
  padding: 0;
  margin-bottom: 0.125rem;
}

.consent-item label,
.reverse-consent-item label {
  color: #696969;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.4375rem;
}

.consent-item [type="checkbox"]:not(:checked),
.consent-item [type="checkbox"]:checked,
.reverse-consent-item [type="checkbox"]:not(:checked),
.reverse-consent-item [type="checkbox"]:checked {
  position: absolute;
  left: -624.9375rem;
}

.consent-item [type="checkbox"]:not(:checked) + label,
.consent-item [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.5625rem;
  cursor: pointer;
  margin-left: 0;
}

.reverse-consent-item [type="checkbox"]:not(:checked) + label,
.reverse-consent-item [type="checkbox"]:checked + label {
  position: relative;
  padding-right: 1.5625rem;
  cursor: pointer;
  margin-left: 0;
}

.consent-item [type="checkbox"]:not(:checked) + label::before,
.consent-item [type="checkbox"]:checked + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 1.0625rem;
  height: 1.0625rem;
  border: 0.125rem solid #696969;
  background: none;
}

.reverse-consent-item [type="checkbox"]:not(:checked) + label::before,
.reverse-consent-item [type="checkbox"]:checked + label::before {
  content: '';
  position: absolute;
  right: 0.15625rem;
  top: 2px;
  width: 1.0625rem;
  height: 1.0625rem;
  border: 0.125rem solid #696969;
  background: none;
}

.consent-item [type="checkbox"]:not(:checked) + label::after,
.consent-item [type="checkbox"]:checked + label::after {
  content: '×';
  position: absolute;
  top: -0.6875rem;
  left: 0.15625rem;
  font-size: 1.5rem;
  color: #696969;
  line-height: 1.75;
  transition: all 0.2s;
}

.reverse-consent-item [type="checkbox"]:not(:checked) + label::after,
.reverse-consent-item [type="checkbox"]:checked + label::after {
  content: '×';
  position: absolute;
  top: -0.6875rem;
  right: 0.3rem;
  font-size: 1.5rem;
  color: #696969;
  line-height: 1.75;
  transition: all 0.2s;
  font-weight: 400;
}

.consent-item [type="checkbox"]:not(:checked) + label::after,
.reverse-consent-item [type="checkbox"]:not(:checked) + label::after {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.consent-item [type="checkbox"]:checked + label::after,
.reverse-consent-item [type="checkbox"]:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.consent-item [type="checkbox"]:disabled:not(:checked) + label::before,
.consent-item [type="checkbox"]:disabled:checked + label::before,
.reverse-consent-item [type="checkbox"]:disabled:not(:checked) + label::before,
.reverse-consent-item [type="checkbox"]:disabled:checked + label::before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

.consent-item [type="checkbox"]:disabled:checked + label::after,
.reverse-consent-item [type="checkbox"]:disabled:checked + label::after {
  color: #999;
}

.consent-item [type="checkbox"]:disabled + label,
.reverse-consent-item [type="checkbox"]:disabled + label {
  color: #aaa;
}

.consent-item [type="checkbox"]:checked:focus + label::before,
.consent-item [type="checkbox"]:not(:checked):focus + label::before,
.reverse-consent-item [type="checkbox"]:checked:focus + label::before,
.reverse-consent-item [type="checkbox"]:not(:checked):focus + label::before {
  border: 1px solid #fff;
}

.gform_footer input.button {
  margin-bottom: 16px !important;
}

#gform_fields_1 {
  list-style-type: none !important;
}

.number_box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #000;
  line-height: 25px;
  text-align: center;
  padding: 9px;
  font-size: 24px;
  border: 2px solid #13508c;
  display: inline-block;
  margin: 2px;
}

.number_text {
  margin-left: 10px;
  font-size: 1.5rem;
  color: #696969;
}

.sub-capability div {
  margin-left: 60px;
  margin-bottom: 0;
  padding: 5px;
}

.sub-capability code {
  margin: 3px;
  display: inline-block;
}

.subscription-block-title {
  display: inline-block;
  text-decoration: none;
  font-size: 1.5em;
  background-image: linear-gradient(to right, #1b4886, #1b4886);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: background-size 0.5s ease;
  margin-bottom: 10px;
  color: #696969;
}

.whitelist-tab-section {
  border: 2px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.whitelist-tab-section-open {
  margin-bottom: 0 !important;
}

.whitelist-tab-section > h4 {
  margin-bottom: 0 !important;
  font-size: 1.5em;
  color: #696969;
}

.whitelist-tab-section > h4::before {
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-right-g.svg") no-repeat center center;
  top: 0;
  content: '';
  width: 1.25rem;
  height: auto;
  margin-top: 0;
  position: absolute;
  bottom: 0;
  right: 10px;
  background-size: 1.25rem 0.75rem;
  transition: all 0.2s;
}

.open-gray {
  margin: 0;
}

.open-gray::before {
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-down-g.svg") no-repeat center center;
  -ms-transform: rotate(90deg);

  /* IE 9 */
  -webkit-transform: rotate(90deg);

  /* Safari */
  transform: rotate(90deg);
}

.whitelist-tab-panel {
  padding: 10px;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  margin-bottom: 10px;
}

.api-key {
  list-style: none;
}

.api-key-name {
  font-size: 1em;
  background-color: #4678ad;
  color: #fff;
  padding: 2px 10px 2px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 0;
}

.api-key-middle {
  font-size: 1em;
  margin-left: -3px;
  background-color: #eee;
  padding: 2px 4px 2px 04px;
}

.api-key-info {
  font-size: 1em;
  margin-left: -3px;
  background-color: #eee;
  padding: 2px 4px 2px 04px;
  border-radius: 0 4px 4px 0 !important;
  -webkit-border-radius: 0 4px 4px 0 !important;
  -moz-border-radius: 0 4px 4px 0 !important;
}

.modal-title {
  color: #1b4886 !important;
}

.create-btn {
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: none;
}

.fade-element-in.ng-enter {
  transition: 0.8s linear all;
  opacity: 0;
}

.fade-element-in-init .fade-element-in.ng-enter {
  opacity: 1;
}

.fade-element-in.ng-enter.ng-enter-active {
  opacity: 1;
}

.fade-element-in.ng-leave {
  transition: 0.3s linear all;
  opacity: 1;
}

.fade-element-in.ng-leave.ng-leave-active {
  opacity: 0;
}

.cc_logo {
  width: 32px;
  margin-right: 10px;
  float: left;
}

.cc_status {
  margin-top: 8px;
  float: left;
  font-size: 1.2em;
  line-height: 1.25;
  font-weight: bold;
}

.change_neg {
  color: #db1212;
}

.change_pos {
  color: #318c26;
}

.thick-blue {
  border: none;
  height: 1px;
  color: #13508c; /* old IE */
  background-color: #13508c; /* Modern Browsers */
}

.glyphicon-large {
  min-height: 1rem;
  line-height: 1.875rem;
  font-size: 0.8rem;
  width: 1em;
  display: block;
}

.hand {
  cursor: pointer;
}

.fa-info-circle {
  color: #033c73;
}

.fa-info-circle:hover {
  color: #0569c7;
}

.custom-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0;
  padding-right: 0;
  max-width: 100% !important;
}

.doc-section {
  background-color: #ebebeb;
  padding-left: 0;
}

.capability-reorder ul[dnd-list] .dndDraggingSource {
  display: none;
}

.capability-reorder ul[dnd-list] .dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 42px;
}

.capability-reorder ul[dnd-list] li {
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 3.125rem;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  cursor: move;
}

.myaccount-tab table > thead > tr > th {
  color: #298dff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
}

.myaccount-tab table > tbody > tr > td,
.myaccount-tab .btn {
  color: #696969;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

.myaccount-tab .alert-warning {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

.control-label {
  color: #696969;
  font-size: 1.1875rem;
}

.mobile-menu-a a {
  text-decoration: underline !important;
  color: #696969 !important;
  margin-right: 1.875rem;
  line-height: 1.625rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 0.9375rem 0;
  border-bottom: 0.125rem solid #f5f5f5;
}

.custom-select {
  border-radius: 4px;
  font-size: 14px;
  color: #298dff;
  padding-left: 12px;
}

.usage-well {
  display: flex;
  width: 100%;
  align-items: center;
}

.usage-well-cols {
  flex: 1;
}

.modal-body .dl-horizontal dt,
.modal-body .dl-horizontal dd,
.modal-body p {
  color: #696969;
  font-size: 1.1875rem;
}

.custom-label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 1.1875rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fff;
}

.custom-label-danger {
  background-color: #c71c22;
}

.custom-label-success {
  background-color: #73a839;
}

.zindex {
  z-index: 99000 !important;
}

.zindex-plus {
  z-index: 99001 !important;
}

.zindex-double-plus {
  z-index: 99002 !important;
}

.zindex-master {
  z-index: 99999 !important;
}

.check-text-danger {
  color: #b94a48 !important;
  font-style: italic;
}

.pro-button {
  padding: 0;
}

.pro-button > a {
  margin-left: 0;
  width: 100%;
}

.custom-notification {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  color: #fff;
  font-weight: 600;
}

.search-bar-btn {
  background: #1b4987 url('https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/search-icon-w.svg') no-repeat 90%;
  color: #fff;
  width: 100% !important;
  position: absolute;
  right: 0;
  text-align: center !important;
  display: inline;
  height: 3.125rem !important;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.5rem !important;
  font-weight: 600;
  border: none;
  background-size: 1.25rem 1.25rem;
}

.search-bar-btn:hover {
  color: #fff;
  opacity: 0.8;
}

.small-btn {
  margin-left: 0;
  line-height: 0;
}

.small-btn:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.eq-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.eq-row > [class*='col-'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-title-row {
  background-color: #fff;
}

.product-title-text {
  color: #1b4886;
  font-weight: 600;
  font-size: 1.1875rem;
  line-height: 1.1875rem;
  padding-left: 0 !important;
  padding-bottom: 20px;
}

.product-title-text a {
  text-decoration: underline;
}

.product-title > h1 {
  margin-bottom: 1.25rem;
}

.modal-content {
  border-radius: 0 !important;
}

.modal-body {
  padding: 0 !important;
}

.modal-body > .row {
  padding: 0;
}

.receipt-well {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
}

.top-menu {
  padding-bottom: 10px;
  padding-top: 20px;
  min-height: 3.125rem;
}

.account-block-body .top-menu-link {
  color: #298dff !important;
  font-weight: 600;
}

.account-block-body .top-menu-link::after {
  margin-left: 0.4375rem;
  top: 0.25rem;
  content: " ";
  display: inline-block;
  width: 1.6875rem;
  height: 1.1625rem;
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-right-blue.svg") no-repeat 50%;
  background-size: 1.6875rem 1.1625rem;
}

.code-holder {
  overflow-x: scroll;
}

.code-holder code,
.code-holder script {
  white-space: nowrap;
}

.code-holder span {
  font-size: 1rem !important;
  font-family: 'Source Sans Pro', sans-serif;
  color: #696969;
}

.row {
  max-width: 1440px !important;
}

.top-menu-btn {
  height: 2.9vw;
  display: block;
  text-align: center;
  font-size: 1.1875vw;
  font-weight: 600;
  margin-bottom: 1.25vw;
}

.form-section {
  color: #298dff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625rem;
}

[data-braintree-id="toggle"] {
  display: none;
}

.custom-modal-footer > div[class*='col-md-'] {
  margin-bottom: 5px;
}

.filters-accordion {
  word-wrap: break-word !important;
}

.impersonate {
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 40px;
}

.impersonate > div {
  position: fixed;
  width: 100%;
  z-index: 999 !important;
  background-color: rgb(217, 237, 247, 0.5) !important;
  margin-bottom: 0 !important;
}

.trial-warning > div {
  background-color: #fcf8e3 !important;
}

.payment {
  margin-top: 20px !important;
}

.goto-link {
  color: #696969 !important;
  font-weight: 600;
}

.goto-link::after {
  margin-left: 0.4375rem;
  top: 0.25rem;
  content: " ";
  display: inline-block;
  width: 1.6875rem;
  height: 1.1625rem;
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/full-arrow-right-g.svg") no-repeat 50%;
  background-size: 1.6875rem 1.1625rem;
}

.token {
  margin-bottom: 5px;
}

.token > span {
  color: #1b4886;
  font-weight: 700;
  font-size: 1.1875rem;
}

.token div {
  font-size: 1.1875rem;
  font-weight: 400;
}

.signup-block {
  font-size: 1.1875rem !important;
  font-family: 'Source Sans Pro', sans-serif;
  color: #696969;
}

.signup-block ul > li > ul {
  list-style-type: circle;
}

.status-btn {
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
  line-height: 1em !important;
  font-size: .6em !important;
}

.custom-table-trials th,
.custom-table-trials td {
  text-align: left;
}

.custom-table-trials .matching-btn-text-width,
.custom-table-product .matching-btn-text-width {
  margin-left: 0 !important;
}

.trials.expired td {
  color: #d14704;
}

.trials.expiring td {
  color: #6a8e2c;
}

.trial-block {
  text-align: center;
}

.trial-block p {
  font-size: 1rem;
  font-weight: 600;
  color: #298dff;
  margin-bottom: 0 !important;
}

.trial-block .image-row {
  display: block;
  text-align: center;
  height: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.trial-block-a {
  font-size: 1rem;
  color: #298dff !important;
  font-weight: 600;
}

.trial-block-a::after {
  margin-left: 0.4375rem;
  top: 0.25rem;
  content: " ";
  display: inline-block;
  width: 1.6875rem;
  height: 1.1625rem;
  background: url("https://www.scientiamobile.com/wp-content/themes/scientiamobile/dist/assets/img/arrow-right-blue.svg") no-repeat 50%;
  background-size: 1.6875rem 1.1625rem;
}

.table-range-selected {
  font-weight: bold !important;
}

.settings-btn {
  cursor: pointer;
  padding: 3px 10px;
  width: 6.125rem;
  border: 0.125rem solid #1b4886;
  border-radius: 3.125rem;
}

.settings-btn small {
  color: #1b4886;
}

.settings-btn:hover {
  border-color: #298dff;
}

.settings-btn:hover small {
  color: #298dff;
}

.settings-btn-selected {
  cursor: pointer;
  background-color: #1b4886;
  border: 0.125rem solid #1b4886;
  padding: 3px 10px;
  width: 6.125rem;
  border-radius: 3.125rem;
}

.settings-btn-selected small {
  color: #fff;
}

md-slider.md-default-theme.md-primary .md-sign,
md-slider.md-primary .md-sign {
  background-color: #298dff !important;
}

md-slider.md-default-theme.md-primary .md-thumb::after,
md-slider.md-primary .md-thumb::after {
  border-color: #298dff !important;
  background-color: #298dff !important;
}

md-slider.md-default-theme.md-primary .md-track.md-track-fill,
md-slider.md-primary .md-track.md-track-fill {
  background-color: #298dff !important;
}

md-slider.md-default-theme .md-track-ticks,
md-slider .md-track-ticks {
  color: #298dff !important;
}

md-slider.md-default-theme.md-primary .md-sign::after,
md-slider.md-primary .md-sign::after {
  border-top-color: #298dff !important;
}
